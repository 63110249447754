<script setup lang="ts">
import type { KeenSliderOptions, KeenSliderInstance } from "keen-slider";
import { useKeenSlider } from "keen-slider/vue";

import type { HTMLProps } from "../types-utils";

const DEFAULT_AUTOPLAY_DELAY = 2000;

const props = withDefaults(
	defineProps<
		{
			options: KeenSliderOptions<{ autoplay?: { delay: number } }>;
			fadeMode?: boolean;
		} & HTMLProps
	>(),
	{ fadeMode: false }
);
const sliderActiveSlide = ref(0);
const timeoutId = ref<ReturnType<typeof setTimeout>>();

const startAutoplay = (slider: KeenSliderInstance) => {
	clearTimeout(timeoutId.value);

	if (slider.slides.length < 2) {
		return;
	}

	timeoutId.value = setTimeout(() => {
		if (slider.track.details) {
			slider.track.details.maxIdx === slider.track.details.abs ? slider.moveToIdx(0) : slider.next();
		}
	}, props.options?.autoplay?.delay ?? DEFAULT_AUTOPLAY_DELAY);
};

const defaultOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto", spacing: 8 },
	created(slider) {
		sliderActiveSlide.value = slider.track.details?.abs ?? 0;
		if (props.options?.autoplay) {
			startAutoplay(slider);
		}
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
		if (props.options?.autoplay) {
			startAutoplay(slider);
		}
	},
	optionsChanged(slider) {
		if (props.options?.autoplay && !timeoutId.value) {
			startAutoplay(slider);
		}
	}
};
const [container, slider] = useKeenSlider({ ...defaultOptions, ...props.options });
defineExpose({ slider });
</script>

<template>
	<div ref="container" :class="fadeMode ? 'fader' : 'keen-slider'">
		<slot :slider="slider" :activeIndex="sliderActiveSlide" />
	</div>
	<slot name="dots" :slider="slider" :activeIndex="sliderActiveSlide" />
</template>

<style lang="scss" scoped>
@import url("keen-slider/keen-slider.css");

.fader {
	height: 340px;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		height: 433px;
	}
}
</style>
